<template>
    <b-modal size="xl"
             centered
             hide-header
             hide-footer
             content-class="rounded-xl border-0 shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="position-sticky header d-flex justify-content-between align-items-center bg-white pb-1 mb-3">
            <h1 class="font-weight-bolder mb-0">
                {{ promotion ? "Update" : "Create" }} promotion
            </h1>
            <button class="btn btn-lg btn-outline-dark border-0"
                    v-on:click="hideModal">
                <font-awesome-icon icon="xmark"></font-awesome-icon>
            </button>
        </div>
        <form v-on:submit.prevent.stop="submit"
              v-on:reset="reset">
            <div class="form-row">
                <div class="col-lg-5 col-xl-4">
                    <b-form-group label="Promotion Thumbnails"
                                  label-for="promotionImages">
                        <div class="position-relative promotion-images img-thumbnail rounded-xl"
                             v-on:click="changeImages">
                            <input id="promotionImages"
                                   class="d-none"
                                   type="file"
                                   multiple
                                   v-on:change="updateImages">
                            <img class="rounded-xl"
                                 alt="Event images"
                                 v-bind:src="images[0]">
                        </div>
                    </b-form-group>
                    <h6 class="text-center">
                        Click to update 👆
                    </h6>
                    <draggable class="thumbnails d-flex flex-wrap"
                               v-model="images">
                        <div class="thumbnail-wrapper pr-1 pb-1"
                             v-for="image in images"
                             v-bind:key="image">
                            <img class="img-thumbnail h-100 w-100"
                                 alt="Event Thumbnails"
                                 v-bind:src="image">
                        </div>
                    </draggable>
                </div>
                <div class="col-lg-7 col-xl-8 form-row">
                    <b-form-group class="col-md-6"
                                  label-for="active">
                        <b-form-checkbox id="active"
                                         switch
                                         v-model="active">
                            {{ active ? "🟢Active" : "🔴Inactive" }}
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="col-md-6"
                                  label-for="promoted">
                        <b-form-checkbox id="promoted"
                                         switch
                                         v-model="promoted">
                            {{ promoted ? "🟢Promoted" : "🔴Normal" }}
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group class="col-12"
                                  label="Heading *"
                                  label-for="heading">
                        <template v-slot:label>
                            ✒️ Heading <small class="text-danger">* Required</small>
                        </template>
                        <b-form-input class="rounded-pill"
                                      id="heading"
                                      name="heading"
                                      type="text"
                                      required
                                      v-model.trim="heading">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group class="col-12"
                                  label="Subheading"
                                  label-for="subheading">
                        <template v-slot:label>
                            ✒️ Subheading
                        </template>
                        <b-form-input class="rounded-pill"
                                      id="subheading"
                                      name="subheading"
                                      type="text"
                                      v-model="subheading">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  label-for="category">
                        <template v-slot:label>
                            🏷️ Category <small class="text-danger">* Required</small>
                        </template>
                        <b-form-select class="rounded-pill"
                                       id="category"
                                       name="category"
                                       required
                                       value-field="_id"
                                       text-field="name"
                                       v-bind:options="categories"
                                       v-model="category">
                            <template v-slot:first>
                                <option v-bind:value="null">
                                    Please select a promotion category.
                                </option>
                            </template>
                        </b-form-select>
                    </b-form-group>

                    <div class="col-md-6 d-flex align-items-end mb-3">
                        <button class="btn btn-link"
                                v-on:click="isAddCategoryModalOpened = true">
                            Create a new category
                        </button>
                    </div>

                    <div class="col-md-6">
                        <b-form-group>
                            <template v-slot:label>
                                🌏 Region <small class="text-danger">* Required</small>
                            </template>
                            <b-form-select class="rounded-pill"
                                           v-model="region">
                                <option v-for="{ _id, name, code } in regions"
                                        v-bind:key="_id"
                                        v-bind:value="code">
                                    {{ name }} ({{ code }})
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <b-form-group class="col-12"
                                  label=""
                                  label-for="description">
                        <template v-slot:label>
                            📄 Description <small class="text-danger">* Required</small>
                        </template>
                        <b-form-textarea class="rounded-xl"
                                         id="description"
                                         name="description"
                                         required
                                         placeholder="Enter description..."
                                         rows="6"
                                         max-rows="10"
                                         v-model="description">
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group class="col-12"
                                  label=""
                                  label-for="weblink">
                        <template v-slot:label>
                            🔗 Web Link
                        </template>
                        <b-form-input class="rounded-pill"
                                      id="weblink"
                                      name="weblink"
                                      type="url"
                                      v-model.trim="webLink">
                        </b-form-input>
                    </b-form-group>

                    <div class="col-12 mb-3">
                        <div class="card rounded-xl shadow-sm overflow-hidden">
                            <div class="card-header d-flex justify-content-between pl-3 pr-3 py-2"
                                 v-on:click="isCouponOpened = !isCouponOpened">
                                <div>
                                    <strong>🎫 Coupon Code</strong>
                                </div>
                                <div class="rotatable"
                                     v-bind:class="{ 'rotated': isCouponOpened }">
                                    <font-awesome-icon v-bind:icon="['far', 'chevron-down']"></font-awesome-icon>
                                </div>
                            </div>
                            <b-collapse v-model="isCouponOpened">
                                <div class="card-body px-3 py-2">
                                    <coupon-preview class="mb-3"
                                                    v-if="couponCode && couponType !== 'plaintext'"
                                                    v-bind:coupon-code="couponCode"
                                                    v-bind:coupon-type="couponType">
                                    </coupon-preview>
                                    <p class="text-center text-danger"
                                       v-if="couponType === 'EAN13'">
                                        EAN13 barcodes support only <strong>numeric</strong> characters and must contain <strong>12</strong> characters.
                                    </p>
                                    <b-form-group label="Coupon Code"
                                                  label-for="couponCode">
                                        <b-form-input id="couponCode"
                                                      name="couponCode"
                                                      type="text"
                                                      size="sm"
                                                      v-model="couponCode">
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group label="Coupon Type"
                                                  label-for="couponType">
                                        <b-form-select id="couponType"
                                                       name="couponType"
                                                       size="sm"
                                                       v-bind:required="couponCode"
                                                       v-model="couponType">
                                            <b-form-select-option v-for="type in couponTypes"
                                                                  v-bind:key="type"
                                                                  v-bind:value="type">
                                                {{ type }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                    <b-form-group class="col-12"
                                  label-for="attributes">
                        <attribute-stack class="w-100"
                                         v-model="attributes">
                            <strong>📋 Attributes</strong>
                        </attribute-stack>
                    </b-form-group>
                </div>
            </div>
            <div class="buttons text-center">
                <button class="btn btn-primary rounded-pill px-4 mr-2"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Save
                </button>
                <button class="btn btn-secondary rounded-pill px-4"
                        type="reset"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    ♻️ Reset
                </button>
            </div>
        </form>
        <modal-category type="promotion"
                        v-model="isAddCategoryModalOpened">
        </modal-category>
    </b-modal>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import {
    BModal, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormCheckbox, BFormTextarea, BCollapse,
} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
    name: "ModalPromotion",
    components: {
        BModal, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BFormCheckbox, BFormTextarea, BCollapse,
        draggable,
        ModalCategory: () => import("../modals/ModalCategory"),
        CouponPreview: () => import("./ModalPromotion/CouponPreview"),
        AttributeStack: () => import("./ModalPromotion/AttributeStack"),
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "promotion": {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,
            isCouponOpened: false,
            isAddCategoryModalOpened: false,

            promotionId: "",
            active: false,
            promoted: false,
            heading: "",
            subheading: "",
            category: null,
            region: "",
            description: "",
            attributes: [
                {
                    name: "🏢 地址",
                    value: "An example. Can be edited or deleted. ",
                },
                {
                    name: "🕰 營業時間",
                    value: "An example. Can be edited or deleted. ",
                },
                {
                    name: "☎️ 電話號碼",
                    value: "An example. Can be edited or deleted. ",
                },
            ],
            webLink: "",
            couponCode: "",

            couponTypes: [ "plaintext", "qrcode", "CODE128", "EAN13", ],
            couponType: "plaintext",

            images: [],
            imageFiles: [],
        };
    },
    computed: {
        categories () {
            return this.$store.getters["category/itemsByTypePromotion"];
        },
        regions () {
            return this.$store.getters["region/itemsActive"];
        },
        availableCategories () {
            return this.categories.filter(({ _id }) => !this.category.includes(_id));
        },
        selectedCategories () {
            return this.category.map(
                category => this.categories.find(({ _id }) => _id === category)
            );
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        changeImages () {
            document.getElementById("promotionImages").click();
        },
        async updateImages (e) {
            const temp = Array.from(e.target.files || e.dataTransfer.files);
            if (temp.length === 0) {
                return;
            }
            this.imageFiles = temp;
            this.images = await Promise.all(
                this.imageFiles.map(
                    file => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        return new Promise(
                            (resolve) => {
                                reader.onload = (e) => {
                                    resolve(e.target.result);
                                };
                            }
                        );
                    }
                )
            );
        },
        async submit () {
            if (this.promotion) {
                return this.edit();
            }
            return this.add();
        },
        async add () {
            const {
                active, promoted,
                heading, subheading,
                category, region, description,
                attributes, webLink, couponCode, couponType,
                imageFiles,
            } = this;
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "promotion/add",
                    {
                        active, promoted,
                        heading, subheading,
                        category, region, description,
                        attributes, webLink, couponCode, couponType,
                        "images": imageFiles,
                    }
                );
            } catch (e) {
                console.error(e);
                return;
            } finally {
                this.isLoading = false;
            }
            this.$emit("input", false);
            this.reset();
        },
        async edit () {
            const {
                promotionId,
                active, promoted,
                heading, subheading,
                category, region, description,
                attributes, webLink, couponCode, couponType,
                imageFiles,
            } = this;
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "promotion/edit",
                    {
                        "_id": promotionId,
                        active, promoted,
                        heading, subheading,
                        category, region, description,
                        attributes, webLink, couponCode, couponType,
                        "images": imageFiles,
                    }
                );
            } catch (e) {
                console.error(e);
                return;
            } finally {
                this.isLoading = false;
            }
            this.$emit("input", false);
        },
        reset () {
            this.active = false;
            this.promoted = false;
            this.heading = "";
            this.subheading = "";
            this.category = null;
            this.region = "";
            this.description = "";
            this.attributes = [
                {
                    name: "🏢 地址",
                    value: "An example. Can be edited or deleted. ",
                },
                {
                    name: "🕰 營業時間",
                    value: "An example. Can be edited or deleted. ",
                },
                {
                    name: "☎️ 電話號碼",
                    value: "An example. Can be edited or deleted. ",
                },
            ];
            this.webLink = "";
            this.couponCode = "";
            this.couponType = "plaintext";
            this.images = [];
            this.imageFiles = [];
        },
    },
    mounted () {
        if (this.promotion) {
            const {
                _id, active, promoted, heading, subheading, category, region, description,
                attributes, webLink, couponCode, couponType, images,
            } = this.promotion;
            this.promotionId = _id;
            this.active = active;
            this.promoted = promoted;
            this.heading = heading;
            this.subheading = subheading;
            this.category = category._id;
            this.region = region;
            this.description = description;
            this.attributes = attributes;
            this.webLink = webLink;
            this.couponCode = couponCode;
            this.couponType = couponType;
            this.images = images ?
                images.map(
                    image => `${ apiUrl }/${ apiVersion }/promotion/image/${ image }`
                ) :
                [];
        }
    },
    watch: {
        promotion (newVal) {
            if (newVal) {
                const {
                    _id, active, promoted, heading, subheading, category, region, description,
                    attributes, webLink, couponCode, couponType, images,
                } = newVal;
                this.promotionId = _id;
                this.active = active;
                this.promoted = promoted;
                this.heading = heading;
                this.subheading = subheading;
                this.category = category._id;
                this.region = region;
                this.description = description;
                this.attributes = attributes;
                this.webLink = webLink;
                this.couponCode = couponCode;
                this.couponType = couponType;
                this.images = images ?
                    images.map(
                        image => `${ apiUrl }/${ apiVersion }/promotion/image/${ image }`
                    ) :
                    [];
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.header {
    top: 0;
    z-index: 1;
}

.promotion-images {
    min-height: 200px;

    & > img {
        height: auto;
        width: 100%;
    }

    &:hover,
    &:focus {
        &:after {
            content: "Alter promotion images";

            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            color: rgba(255, 255, 255, 1);
            background-color: rgba(0, 0, 0, 0.25);

            font-size: 2rem;
            font-weight: bolder;

            cursor: pointer;
        }
    }
}

.thumbnails {
    .thumbnail-wrapper {
        height: 90px;
        width: 25%;

        @include media-breakpoint-up(lg) {
            width: 33.33%;
        }

        @include media-breakpoint-up(xl) {
            width: 25%;
        }

        & > .img-thumbnail {
            object-fit: contain;
        }
    }
}

.rotatable {
    transition: transform 250ms ease;

    .rotated {
        transform: rotate(180deg);
    }
}

.buttons {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 3
}
</style>
